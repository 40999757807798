import {
  EventsGrid,
  Utils,
  HeroBrand,
  FormAttendV3,
  FormSponsorV3,
  FormContactUsV3,
  Hero,
  SectionCallout,
  SEO,
  SocialShare,
} from "Components";
import Config from "Data";
import { graphql } from "gatsby";
import Layout from "Layouts/default";
import moment from "moment";
import React from "react";
import { Gap } from "Styles";
import styled from "styled-components";

const BrandedColor = styled.span`
  color: ${Utils.BrandColor(Config.brand.toLowerCase())};
`;

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};
class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentModal: null,
      shareEvent: null,
      showEvents: false,
      isActive: false,
    };
  }

  componentDidMount() {
    this.setState({
      currentModal: null,
      shareEvent: null,
      showEvents: false,
    });
  }

  toggleModal = (key, param) => (e) => {
    e.preventDefault();
    if (this.state.currentModal) {
      this.handleModalCloseRequest();
      return;
    }

    this.setState({
      ...this.state,
      currentModal: key,
      shareEvent: param.shareEvent,
      showEvents: param.showEvents,
    });
  };

  handleModalCloseRequest = () => {
    this.setState({
      ...this.state,
      currentModal: null,
    });
  };

  handleOnAfterOpenModal = () => {};

  render() {
    const { data } = this.props;
    const hero = data.hero.edges[0].node;
    const heroBackground = data.heroBackground.edges[0].node;

    const triangle = data.triangle;
    let allEventsData = [].concat(
      data.events2020.edges,
      data.events2021.edges,
      data.events2022.edges,
      data.events2023.edges,
      data.events2024.edges
    );

    let allBrands = [].concat(
      data.brands2020.distinct,
      data.brands2021.distinct,
      data.brands2022.distinct,
      data.brands2023.distinct,
      data.brands2024.distinct
    );
    allBrands = allBrands.filter(onlyUnique);
    // console.log(allBrands);
    let allEvents = allEventsData.sort((a, b) =>
      a.node.date > b.node.date ? 1 : -1
    );
    const allRegions = ["apac", "canada", "emea", "latam", "usa"];

    const { currentModal } = this.state;
    let futureEvents = allEvents.filter((event) => {
      return !moment(event.node.date).isBefore(moment(), "day");
    });
    futureEvents.sort((a, b) => (a.node.date > b.node.date ? 1 : -1));
    // console.log({ futureEvents })

    return (
      <Layout
        locale={"en-us"}
        region={"us"}
        sticky={true}
        transparentHeader={false}
        transparentFooter={false}
        path={this.props.path}
      >
        <SEO
          pathname="/events/"
          title={`Events Calendar | ${Config.brandBusiness} by ${Config.contact.name}`}
          description={
            `Interested in attending or sponsoring one of our upcoming events?` ||
            ""
          }
          keywords={"pageKeywords"}
          // banner={heroBackground.childrenFile[0].childImageSharp.gatsbyImageData.images.sources[0].srcSet || ""}
          events={allEvents}
        />

        <SocialShare
          pathname="/events/"
          title={`Events Calendar | ${Config.brandBusiness} by ${Config.contact.name}`}
          description={
            `Interested in attending or sponsoring one of our upcoming events?` ||
            ""
          }
          // banner={heroBackground.childrenFile[0].childImageSharp.gatsbyImageData.images.sources[0].srcSet || ""}
        />
        <HeroBrand
          height={50}
          justify={"end"}
          featured={false}
          title={`Calendar`}
          subtitle={`Interested in attending or sponsoring one of our upcoming events?`}
          image={heroBackground.childImageSharp.gatsbyImageData}
          colorPlain={"#000"}
          animated={"true"}
          event_type={"cfo.org"}
          cta_action1_text={"Attend"}
          cta_action1={this.toggleModal("modal_attend", {
            shareEvent: null,
            showEvents: true,
          })}
          cta_action2_text={"Sponsor"}
          cta_action2={this.toggleModal("modal_sponsor", {
            shareEvent: null,
            showEvents: true,
          })}
          vimeoId={hero.video && hero.video !== null ? hero.video : undefined}
          track={"Hero"}
        />

        <SectionCallout
          small={true}
          dark={false}
          grid={true}
          page={this.props}
          background={
            triangle.edges[0].node.childImageSharp.gatsbyImageData.images
              .sources[0].srcSet
          }
        >
          {console.log(allBrands)}
          <EventsGrid
            button={true}
            countdown={false}
            events={allEvents}
            regions={allRegions}
            brands={allBrands}
            featured={false}
            big={false}
            filter={true}
            header={true}
            now={false}
            title={``}
            track={"Events Grid"}
            item_action={this.toggleModal("modal_contactus", {
              shareEvent: true,
              showEvents: true,
            })}
          />
          {/* <SectionSubscribe
            dark={false}
            title={`Want to Stay informed?`}
            // subtitle={`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non, voluptates.`}
            postSubscribe={true}
            track={"Subscribe CTA"}
          /> */}
          <Gap />
        </SectionCallout>

        <FormAttendV3
          events={futureEvents}
          showEvents={this.state.showEvents}
          isOpen={currentModal === "modal_attend"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_attend", {
            shareEvent: null,
            showEvents: false,
          })}
        />

        <FormSponsorV3
          events={futureEvents}
          showEvents={this.state.showEvents}
          options={false}
          optionsLink={false}
          isOpen={currentModal === "modal_sponsor"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_sponsor", {
            shareEvent: null,
            showEvents: false,
          })}
        />

        <FormContactUsV3
          title={`Have a question for the ${Config.brand} team?`}
          subtitle="Call us or submit below:"
          isOpen={currentModal === "modal_contactus"}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal("modal_contactus")}
        />
      </Layout>
    );
  }
}

export default Events;

export const query = graphql`
  query EventsUSQuery {
    hero: allGoogleSheetsEvents(filter: { block: { eq: "Hero" } }) {
      edges {
        node {
          id
          block
          heroImage
          title
          subtitle
          childrenFile {
            childImageSharp {
              gatsbyImageData(width: 2880, placeholder: BLURRED)
            }
          }
        }
      }
    }
    heroBackground: allFile(filter: { name: { eq: "cmocfo_homepage" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 2880, placeholder: BLURRED)
          }
        }
      }
    }
    triangle: allFile(filter: { name: { eq: "triangle" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 2880, placeholder: BLURRED)
          }
        }
      }
    }
    events2020: allGoogleSheetOverview2020(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      totalCount
      edges {
        node {
          id
          brand
          region
          events
          eventTitle
          eventType
          eventTime
          audience
          industry
          panel
          stateCity
          date
          eventSite
          twitter
          image
          webinarLink
          country
          countryIso
        }
      }
    }
    regions2020: allGoogleSheetOverview2020(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      distinct(field: region)
      edges {
        node {
          region
        }
      }
    }
    brands2020: allGoogleSheetOverview2020(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      distinct(field: brand)
      edges {
        node {
          brand
        }
      }
    }
    events2021: allGoogleSheetOverview2021(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      totalCount
      edges {
        node {
          id
          brand
          region
          events
          eventTitle
          eventType
          eventTime
          audience
          industry
          panel
          stateCity
          date
          eventSite
          twitter
          image
          webinarLink
          country
          countryIso
        }
      }
    }
    regions2021: allGoogleSheetOverview2021(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      distinct(field: region)
      edges {
        node {
          region
        }
      }
    }
    brands2021: allGoogleSheetOverview2021(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      distinct(field: brand)
      edges {
        node {
          brand
        }
      }
    }
    events2022: allGoogleSheetOverview2022(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      totalCount
      edges {
        node {
          id
          brand
          region
          events
          eventTitle
          eventType
          eventTime
          audience
          industry
          panel
          stateCity
          date
          eventSite
          twitter
          image
          webinarLink
          country
          countryIso
        }
      }
    }
    regions2022: allGoogleSheetOverview2022(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      distinct(field: region)
      edges {
        node {
          region
        }
      }
    }
    brands2022: allGoogleSheetOverview2022(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      distinct(field: brand)
      edges {
        node {
          brand
        }
      }
    }
    events2023: allGoogleSheetOverview2023(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      totalCount
      edges {
        node {
          id
          brand
          region
          events
          eventTitle
          eventType
          eventTime
          audience
          industry
          panel
          stateCity
          date
          eventSite
          twitter
          image
          webinarLink
          country
          countryIso
        }
      }
    }
    regions2023: allGoogleSheetOverview2023(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      distinct(field: region)
      edges {
        node {
          region
        }
      }
    }
    brands2023: allGoogleSheetOverview2023(
      filter: { calendar: { eq: true }, audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      distinct(field: brand)
      edges {
        node {
          brand
        }
      }
    }
    events2024: allGoogleSheetOverview2024(
      filter: { calendar: { eq: true },audience: { eq: "CFO" } } 
      sort: { order: DESC, fields: date }
    ) {
      totalCount
      edges {
        node {
          id
          brand
          region
          events
          eventTitle
          eventType
          eventTime
          audience
          industry
          stateCity
          date
          eventSite
          twitter
          image
          webinarLink
          country
          countryIso
        }
      }
    }
    regions2024: allGoogleSheetOverview2024(
      filter: { calendar: { eq: true } ,audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      distinct(field: region)
      edges {
        node {
          region
        }
      }
    }
    brands2024: allGoogleSheetOverview2024(
      filter: { calendar: { eq: true },audience: { eq: "CFO" } }
      sort: { order: DESC, fields: date }
    ) {
      distinct(field: brand)
      edges {
        node {
          brand
        }
      }
    }
    # months: allGoogleSheetOverview(
    #   filter: {
    #     calendar: { eq: "TRUE" }
    #     brand: { ne: null }
    #     eventType: { in: ["conference", "gala", "camp", "webinar"] }
    #   }
    # ) {
    #   distinct(field: date)
    #   edges {
    #     node {
    #       region
    #       date
    #     }
    #   }
    # }
    # distinct_country: allListEvents(filter: { event: { active: { eq: 1 } } }) {
    #   distinct(field: event___country)
    # }
    # distinct_state: allListEvents(filter: { event: { active: { eq: 1 } } }) {
    #   distinct(field: event___state)
    # }
    # distinct_cities: allListEvents(filter: { event: { active: { eq: 1 } } }) {
    #   distinct(field: event___city)
    # }
    # distinct_sponsors: allListEvents {
    #   distinct(field: event___event_detail___companys___company_name)
    # }
    # distinct_speakers_companies: allListEvents {
    #   distinct(field: event___event_detail___speakers___company_name)
    # }
  }
`;
